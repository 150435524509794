import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

import * as styles from "./footer.module.scss";

import Logo from "../Logo";

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          siteBuildMetadata {
            buildTime(formatString: "DD.MM.YY HH:mm")
          }
        }
      `}
      render={(data) => (
        <footer
          className={`${styles.footer} bg-primary mt-6 font-light py-8 text-sm`}
        >
          <div className="container mx-auto">
            <div
              className={`${styles.underlineLinks} grid md:grid-cols-2 md:w-1/2 mx-auto text-lg text-center gap-10 space-y-8 md:space-y-0`}
            >
              <nav>
                <h3 className="font-header text-2xl font-light text-white mb-6">
                  Institut.
                </h3>
                <div className="flex flex-col">
                  <p>
                    <Link className="text-white no-underline" to="/ueber">
                      Über uns
                    </Link>
                  </p>
                  <p>
                    <Link className="text-white no-underline" to="/forschung">
                      Forschung
                    </Link>
                  </p>
                  <p>
                    <Link className="text-white no-underline" to="/lehre">
                      Lehre
                    </Link>
                  </p>
                  <p>
                    <Link className="text-white no-underline" to="/transfer">
                      Transfer
                    </Link>
                  </p>
                  <p>
                    <Link className="text-white no-underline" to="/aktuelles">
                      Aktuelles
                    </Link>
                  </p>
                  <p>
                    <Link className="text-white no-underline" to="/kontakt">
                      Kontakt
                    </Link>
                  </p>
                </div>
              </nav>
              <nav>
                <h3 className="font-header font-light text-2xl text-white mb-6">
                  Profilfelder.
                </h3>
                <div className="flex flex-col">
                  <p>
                    <Link
                      className="text-white no-underline"
                      to="/corporate-governance"
                    >
                      Corporate Governance
                    </Link>{" "}
                  </p>
                  <p>
                    <Link
                      className="text-white no-underline"
                      to="/digitalethik"
                    >
                      Digitalethik
                    </Link>
                  </p>

                  <p>
                    <Link
                      className="text-white no-underline"
                      to="/nachhaltigkeit"
                    >
                      Nachhaltigkeit
                    </Link>
                  </p>
                  <p>
                    <Link
                      className="text-white no-underline"
                      to="/sozialwirtschaft"
                    >
                      Sozialwirtschaft
                    </Link>
                  </p>
                  <p>
                    <Link
                      className="text-white no-underline"
                      to="/sustainable-finance"
                    >
                      Sustainable Finance
                    </Link>
                  </p>

                  <p>
                    <Link
                      className="text-white no-underline"
                      to="/wertepositionierung"
                    >
                      Wertepositionierung
                    </Link>
                  </p>
                </div>
              </nav>
            </div>

            <div className="text-center mt-10">
              <div className="flex justify-center mb-10">
                <Logo colorScheme="standard" />
              </div>
              <p className="text-white  mb-2">
                i-em. | iLab Ethik und Management ©{" "}
                {new Date().getFullYear()}
              </p>
              <p>
                <Link className="text-white no-underline" to="/datenschutz">
                  Datenschutz
                </Link>

                {" | "}
                <Link className="text-white no-underline" to="/impressum">
                  Impressum
                </Link>
              </p>
            </div>
          </div>
        </footer>
      )}
    />
  );
};

export default Footer;
