import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
//Animate text
export const textIntro = (elem) => {
  gsap.from(elem, {
    xPercent: -20,
    opacity: 0,
    stagger: 0.2,
    duration: 2,
    scale: -1,
    ease: "back",
  });
};

//Open menu
export const menuShow = (elem1) => {
  gsap.to(elem1, {
    duration: 0.092,
    height: "100vh",
    transformOrigin: "right top",
    ease: "power2.out",
  });
};

//Close menu
export const menuHide = (elem1, duration) => {
  gsap.to(elem1, {
    duration: 0.2,
    height: 0,
    ease: "inOut",
    stagger: {
      amount: 0.07,
    },
  });
};
