import PropTypes from "prop-types";
import React, { useState } from "react";

import Header from "./header";
import Footer from "./Footer";

import * as styles from "./layout.module.scss";

function Layout({ children, logoColorScheme }) {
  return (
    <div
      className={`${styles.layout} flex flex-col justify-between min-h-screen font-body text-black bg-light`}
    >
      <Header logoColorScheme={logoColorScheme} />

      <main
        className=""
        style={{
          minHeight: "60vh",
          marginTop: "-200px",
        }} /*"flex-1 w-full max-w-4xl px-4 py-8 mx-auto md:px-8 md:py-16"*/
      >
        {children}
      </main>

      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
