import { Link } from "gatsby";
import React, { useState, useEffect, useRef } from "react";
import gsap from "gsap";
import Headroom from "react-headroom";
import { textIntro, menuShow, menuHide } from "./animation";

import * as styles from "./header.module.scss";

import Logo from "../Logo";
import Hamburger from "./Hamburger";

function Header({ logoColorScheme }) {
  const menuWrap = useRef(null);
  const menu = useRef(null);

  const [isOpen, setOpen] = useState(false);
  const [isInitial, setInitial] = useState(true);
  const toggleOpen = () => {
    setOpen(!isOpen);
    setInitial(false);
  };

  const justClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isOpen === true) {
      //gsap.set(menuWrap.current, { css: { top: "0" } });
      gsap.to(menuWrap.current, {
        display: "block",
        duration: 0,
        opacity: 1,
        height: "100%",
      });
      gsap.to(menu.current, {
        display: "block",
      });
      menuShow(menu.current, isInitial ? 5 : 0);
    }
    if (isOpen === false && isInitial === false) {
      menuHide(menu.current);
      gsap.to(menuWrap.current, {
        display: "none",
        duration: 0,
        delay: 1.2,
      });
    }
  });

  return (
    <div style={{ height: "200px" }}>
      <Headroom style={{ zIndex: 100 }} disable={false}>
        <header
          className={`${isOpen ? "fixed" : null} text-black w-full z-50 top-0`}
        >
          <div className="flex px-6 sm:px-0 md:px-2 flex-wrap items-center justify-between container py-6 md:py-7 mx-auto">
            <Link to="/" onClick={() => justClose()} className="z-50">
              <h1 className="flex items-center no-underline">
                <Logo
                  alt="InnovationLab Ethik & Management"
                  colorSheme={logoColorScheme}
                  isOpen={isOpen}
                />
              </h1>
            </Link>

            <button
              className="flex items-center block right-0 z-50"
              onClick={() => toggleOpen()}
            >
              <Hamburger isOpen={isOpen} />
            </button>
          </div>
        </header>
      </Headroom>
      <div
        ref={menuWrap}
        className="top-0 fixed z-40 bottom-0 right-0 left-0 hidden"
      >
        <nav
          ref={menu}
          className={`bg-primary z-40 text-white overflow-none ${styles.nav} w-full `}
        >
          <div className="mx-auto container mt-12 mb-6 grid md:grid-cols-12">
            <ul
              className={`${styles.underlineLinks} ${styles.routes} grid md:grid-cols-2 md:space-y-0 space-y-8 col-span-12 text-center`}
            >
              {[
                {
                  route: "/ueber",
                  title: "Das iLab.",
                  subRoutes: [
                    { route: "/ueber", title: "Über uns" },
                    { route: `/forschung`, title: `Forschung` },
                    { route: `/lehre`, title: `Lehre` },
                    { route: `/transfer`, title: `Transfer` },
                    {
                      route: `/aktuelles`,
                      title: `Aktuelles`,
                    },
                    {
                      route: `/kontakt`,
                      title: `Kontakt`,
                    },
                  ],
                },

                {
                  route: `/profilfelder`,
                  title: `Profilfelder.`,
                  subRoutes: [
                    {
                      route: "/wertepositionierung",
                      title: "Wertepositionierung",
                    },
                    {
                      route: "/corporate-governance",
                      title: "Corporate Governance",
                    },
                    {
                      route: "/sozialwirtschaft",
                      title: "Sozialwirtschaft",
                    },
                    {
                      route: "/sustainable-finance",
                      title: "Sustainable Finance",
                    },
                    {
                      route: "/digitalethik",
                      title: "Digitalethik",
                    },
                    {
                      route: "/nachhaltigkeit",
                      title: "Nachhaltigkeit",
                    },
                  ],
                },
              ].map((elem) => (
                <li className={`${styles.route}  m-0`} key={elem.title}>
                  <div
                    className={`${styles.link} md:col-span-6 lg:col-span-4 col-span-4`}
                  >
                    <Link
                      className={`leading-loose mt-4 font-header text-3xl mb-4 font-light`}
                      key={elem.title}
                      to={elem.route}
                    >
                      {elem.title}
                    </Link>
                  </div>
                  {elem.subRoutes && (
                    <div className={`${styles.subRoutes}`}>
                      <ul>
                        {elem.subRoutes.map((subRoute) => (
                          <li key={subRoute.route} className={styles.subRoute}>
                            <Link
                              className="font-light leading-loose text-xl"
                              to={subRoute.route}
                            >
                              {subRoute.title}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div
            className={`${styles.underlineLinks} container mx-auto text-center mt-10 font-light`}
          >
            <Link to="/impressum">Impressum</Link> |{" "}
            <Link to="/datenschutz">Datenschutz</Link> |{" "}
            <Link to="/kontakt">Kontakt</Link> |{" "}
            <Link to="https://www.linkedin.com/company/i-em/">LinkedIn</Link>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
